.browser {
  height: 80vh;
  margin: 0 !important;
  min-width: 800px;
}

.browser .toolbar {
  border: solid 1px #ebebeb;
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-bottom: -1px;
}

.browser .toolbar button {
  border: solid 1px #ebebeb !important;
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #fff;
  margin-right: 5px;
  color: #303030;
}

.browser .toolbar button:hover {
  background-color: #f4f4f4;
}

.browser .toolbar i {
  margin: 0;
  color: #595959;
}

.browser .toolbar input {
  width: 100%;
  border: solid 1px #ebebeb;
  padding-left: 15px;
  color: #595959;
}

.browser iframe {
  border: solid 1px #d4d4d5;
  width: 100%;
  height: 100%;
  padding: 10px;
}
