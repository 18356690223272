.row {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  width: 100%;
}

.row #col1 {
  min-width: 400px;
  width: 400px;
}

.row #col2 {
  margin-left: 20px;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

table {
  width: 100%;
  /* background-color: #e8e8e8; */
  border-spacing: 0px;
  border-collapse: collapse;
  /* margin: -2px; */
}

table td,
table th {
  border: solid 1px #e8e8e8 !important;

  padding: 10px;
}

table th {
  /* background-color: #e8e8e8; */
  text-align: left;
  vertical-align: top;
  background-color: #f4f4f4;
  font-size: 1.2em;

  color: rgba(0, 0, 0, 0.6);
}

.envelope {
  border: solid 1px #ebebeb;
  padding: 10px;
  margin-top: -1px;
}
.envelope textarea {
  width: 100%;
  height: 130px;
  margin-bottom: 10px;
  border: dotted 3px #ebebeb;
  padding: 10px;
  resize: none;
  line-height: 1.4em;
}
