.ui.navMenu {
  border-radius: 0 !important;
  /* background-color: #2e404f !important; */
  border-right: 0px !important;
  border-left: 0px !important;
  border-top: 0px !important;
  border-bottom: solid 1px #f4f4f4;

  box-shadow: none;
  margin-bottom: 0px !important;
}

.ui.navMenu > div {
  padding: 20px 20px !important;
}

.ui.navMenu.right {
  margin-right: 0;
  box-shadow: 0;
  border: none;
}
